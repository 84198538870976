// SHOP ORDER COMPLETE 1

.c-shop-order-complete-1 {
	@include reset-padmar();

	.c-message {
		padding: 30px 10px;
	}

	.c-order-summary {
		// padding: 0 20%;
		padding: 0;
	}

	.c-row-item {
		padding: 30px 0;
	}

	.c-row-total {
		margin-right: 30px;
		ul {
			display: flex;
			justify-content: space-between;
		}
	}

	.c-list {
		li {
			margin-top: 10px;
		}
	}

	.c-list-inline {
		li + li {
			margin-left: 20px;
		}
	}
}

// END

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-order-complete-1 {

		.c-order-summary {
			@include reset-padmar();
		}

		.c-image {
			padding-bottom: 30px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */
	.c-shop-order-complete-1 {

		.c-order-summary {
			ul {
				li {
					display: block;
					margin: 0;
					* {
						display: inline-block;
					}
				}
			}
		}

		.c-bank-details {
			.c-border-bottom {
				padding-bottom: 10px;
			}
			ul {
				padding-top: 15px;
				li {
					display: block;
					margin: 0;
				}
			}
		}

		.c-order-details {
			.c-row-total {
				margin: 0;
				ul {
					display: block;
					li {
						display: inline-block;
						width: 100%;
						text-align: left;
						#order-pdf417 {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
