// Header 
$layout_full_page:                                                              'no';  // yes or no
$header_class:																	'6';
$header_desktop-bg-color:														#2f353b;
$header_desktop-navbar-bg:														none;
$header_desktop_border:															0;
$header_min_desktop_border:														0;
$header_min_desktop_shadow:														none;

$header_zindex:			 														$zindex-navbar;
$header_transition: 															0.2s;
$header_height:                                                   				67px;  
$topbar_height:																	44px;
$header_on-scroll-height:                                         				67px; 

// Brand
$brand-link-font-color:  														#ebedf2;
$brand-link-font-color-on-hover:  												#ff6b57;

$top-menu_font-color:															#aeb8c2;  
$top-menu_icon-font-color:														#aeb8c2;

// Mega Menu
$header_desktop_mega-menu-primary-link-padding: 								25px 25px 22px 25px; 
$header_desktop_mega-menu-btn-margin: 											18px 25px 7px 25px; 
$header_desktop_mega-menu-btn-icon-margin: 										8px 10px 0px 10px;   
$header_desktop_mega-menu-quick-sidebar-toggler-padding:						28px 25px 15px 25px;   
  
$header_desktop_min-header-mega-menu-primary-link-padding: 						23px 15px 21px 15px;
$header_desktop_min-header_mega-menu-btn-margin: 								15px 15px 16px 15px; 
$header_desktop_min-header_mega-menu-btn-icon-margin: 							6px 5px 7px 5px;
$header_desktop_min-header_mega-menu-quick-sidebar-toggler-padding:				26px 0 24px 15px;

$header_desktop_mega-menu-quick-search-close-icon-top:							35px;
$header_desktop_mega-menu-quick-search-close-icon-right:						8px;
$header_desktop_mega-menu-quick-search-form-control-padding:					20px 20px 20px 5px;

$mega-menu_desktop_primary-link-font-color:										#ffffff; 
$mega-menu_desktop_primary-link-font-color-on-hover:							#ffffff;
$mega-menu_desktop_primary-link-font-color-on-active:							#ffffff;  

$mega-menu_desktop_min-header-bg-color:											$header_desktop-bg-color;
$mega-menu_desktop_min-header-primary-link-font-color:							#ffffff;  

$quick-search_mobile_input-font-color:                                       	darken($general-head-font-color, 20%);
$quick-search_mobile_input-placeholder-color:                        		 	darken($general-head-font-color, 25%);

@import  'theme/_header';

.c-layout-header.c-layout-header-6 {

	.c-topbar {
		padding: 24px 0;
		background: #fff;
		height: auto;

		.c-brand {
			float: left;
			margin: 0px;
			padding: 8px 0 0 0;

			.c-layout-quick-search-shown & {
				display: block;
			}

			> .c-logo {
				display: inline-block;					
				position: relative;

				&:after {
					top: -2px;
					position: absolute;
					right: -22px;
					content: "";
					width: 1px;
					background: #dfe4e8;
					height: 32px;
					display: inline-block;
				}
			}

			> .c-icons {
				position: relative;
				display: inline-block;
				padding: 0;
				top: 1px;
				margin: 0 0 0 35px;
				list-style: none;

				> li {
					display: inline-block;
			   		padding: 3px 3px 5px 3px; 

		    		> a {
		    			font-size: 16px;

		    			> i {
		    				color: $top-menu_icon-font-color;
		    				font-size: 16px;
		    				padding: 4px;
		    			}
		    		}
		    	}
			}
		}

		.c-top-menu {
			margin: 12px 0 10px 0;
			float: right;

			> .c-links {
				> li {
					padding: 0 7px;

					> a:not(.btn) {
						color: $top-menu_font-color;
						font-weight: 500;
					}

					> .btn {
						margin-top: -4px;  
					}

					&.c-divider {
						width: 1px;
						display: inline-block;

						&:after {
							top: -20px;
							position: absolute;
							right: 7px;
							content: "";
							width: 1px;
							background: #dfe4e8;
							height: 32px;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) { /* 992px */
	.c-layout-header.c-layout-header-6 {
		//	Topbar  
		.c-navbar {
			// Main markup
			line-height: 0px;

			.c-mega-menu {			
				float: none;
				display: block;
				text-align: center;
				line-height: 0px;

				.c-layout-quick-search-shown & {
					display: none;
				}     

				> .nav.navbar-nav {
					line-height: 0px;
					float: none;
					display: inline-block;

					> li {     
						margin: 0 !important;
						padding: 0 !important;
						// link
						> .c-link { 
							padding: $header_desktop_mega-menu-primary-link-padding;    
							min-height: $header_height;
						}

						&:first-child {
							> .c-link {
								padding-left: 5px;
							}
						}

						// Quick sidebar toggler

						> .c-quick-sidebar-toggler {
							padding: $header_desktop_mega-menu-quick-sidebar-toggler-padding;
						}

						// button
						> .c-btn {
							margin: $header_desktop_mega-menu-btn-margin;
						}

						// icon
						> .c-btn-icon {
							margin: $header_desktop_mega-menu-btn-icon-margin;
						}  

						/*
						.c-menu-type-mega {
							margin-top: -6px;
						}
						*/
					}
				}	      	
			}
		}   

		// Quick search
		.c-quick-search {
			> .form-control {
				padding: $header_desktop_mega-menu-quick-search-form-control-padding;
				height: $header_height;
			}

			> span {
				top: $header_desktop_mega-menu-quick-search-close-icon-top;
				right: $header_desktop_mega-menu-quick-search-close-icon-right;
			}
		}

	}

	.c-layout-header.c-layout-header-6.c-navbar-fluid {
		.c-navbar {
			width: 100%;
			float: none;     
			display: block;

			.c-mega-menu {
				float: none;
				width: 100%;
				display: block;

				.c-layout-quick-search-shown & {
					display: none;
				}

				> .nav.navbar-nav {
					width: 100%;
					display: inline-block;

					> li {
						> .c-quick-sidebar-toggler {
							padding-right: 10px;
						}

						> .c-search-toggler {
							padding-right: 5px;
						}

						&.c-search-toggler-wrapper,
						&.c-quick-sidebar-toggler-wrapper {
							float: right;
						}
					}
				}
			}
		}
	}

	.c-layout-header-fixed.c-layout-header-6-topbar .c-layout-page {
  		margin-top: 167px;
  	}

  	.c-page-on-scroll.c-layout-header-fixed .c-layout-header.c-layout-header-6 {
  		height: $header_on-scroll-height;	
  	}
}

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-header.c-layout-header-6 {

		.c-topbar {
			padding: 0;

			.c-top-menu {
				float: none;
				display: block;
				padding: 0;
				margin: 10px 0;
				@include clearfix();	
				text-align: right;	

				.c-links {
					float: right;

					> li {
						&:last-child {
							padding-right: 0;
						}
					}
				}	
			}

			.c-brand {		
				width: 100%;
				display: block;				
				float: none;
				@include clearfix();
				margin: 10px 0 20px 0;

				.c-layout-quick-search-shown & {  
					display: none;
				}

				> .c-logo {
					float: left;
				}

				> .c-icons {
					float: left;
				}

				$general-toggler-color: #f8f9fa;
			 	
			 	> .c-hor-nav-toggler {
			 		float: right;
			 		background: $general-toggler-color;
			 		padding: 5px 7px;
			 		position: relative; 
			 		top: 2px;

					> .c-line { 
						background-color: darken($general-toggler-color, 30%);
					}

					&:active,
					&:focus,
					&:hover {
						background: darken($general-toggler-color, 5%); 

						> .c-line { 
							background-color: darken($general-toggler-color, 35%);
						}	 
					}
			 	}

			 	> .c-search-toggler {
			 		float: right;
			 		position: relative;
			 		top: 2px;

			 		> i {
			 			color: $top-menu_font-color;
			 		}

			 		&:hover {
			 			> i {
			 				color: darken($top-menu_font-color, 20%);
			 			}
			 		}
			 	}
			}
		}

		.c-navbar {
			height: auto;
			.c-mega-menu {
				.c-layout-quick-search-shown & {  
					display: none;   
				}

				margin-bottom: 0px;
			}
		}

		// Quick search

		.c-quick-search {
			margin-top: 0;

			> .form-control {
				background: none;    
				@include placeholder($quick-search_mobile_input-placeholder-color);  
				color: $quick-search_mobile_input-font-color;
				padding: 25px 10px 35px 10px;
				height: 55px;
			}

			// close icon
			> span {
				top: 0px;
				color: $quick-search_mobile_input-font-color; 
			}
		}
	}
}
// END