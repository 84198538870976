//BEGIN: Testimonial 2
.c-content-testimonial-2 {
	background: #fff;
	padding: 50px 30px 50px 30px;

	.c-testimonial {
		font-size: 30px;
		line-height: 35px;
		font-weight: 300;
		color: $general-head-font-color;
		margin: 30px 0 40px 0;
	}

	.c-author {
		bottom:0;
		left:0;

		> .c-name {
			display: inline-block;
			font-size: 16px;
  			color: $general-font-color;
  			font-weight: 400;   
			margin: 6px 0 0 0;
		}

		> .c-portrait {
		  background-size: cover;
		  width: 55px;
		  height: 55px;
		  border-radius: 58px;
		  float: left;
		  margin-right: 20px;
		}

		> .c-position {
			font-size: 12px;
			font-weight: 500;
			margin: 0 0 0 0;
		}
	}
}  

.c-content-testimonial-2-slider {
	.c-title {
		position: absolute;
	  	top: 30px;
	  	left: 45px;  
	  	z-index: 10;
	  	font-size: 14px;
	  	padding: 4px 10px 2px 10px;
	  	font-weight: 400;
	  	color: #ffffff;
	}

	.owl-controls {
		margin: 0;
	  	text-align: right;
	  	position: absolute;
	  	top: 30px;
	  	right: 30px;
	}
}

//END: Testimonial 2

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-testimonial-2 {
		margin-bottom: 20px;
	}
}

// END

