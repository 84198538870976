// Header 
$layout_full_page:                                                              'no';  // yes or no
$header_class:																	'4';
$header_desktop-bg-color:														none;
$header_desktop-navbar-bg:														none;
$header_desktop_border:															0;
$header_min_desktop_border:														1px solid darken(#f7fafb, 5%);
$header_min_desktop_shadow:														none; //0px 3px 9px -5px rgba(0,0,0,0.15);

// Brand
$brand-link-font-color:  														#ebedf2;
$brand-link-font-color-on-hover:  												#ff6b57;

// Quick Search
$quick-search_desktop_input-font-color:  										#69727c;     
$quick-search_desktop_input-placeholder-color:									lighten($quick-search_desktop_input-font-color, 10%);	
$quick-search_desktop_icon-font-color:											lighten($quick-search_desktop_input-font-color, 10%);

// Mega Menu
$mega-menu_desktop_primary-link-font-color:										darken(#69727c, 20%); 
$mega-menu_desktop_primary-link-font-color-on-hover:							darken(#69727c, 20%);
$mega-menu_desktop_primary-link-font-color-on-active:							darken(#69727c, 20%);

$mega-menu_desktop_min-header-bg-color:											#ffffff;
$mega-menu_desktop_min-header-primary-link-font-color:							#69727c;  

@import  'theme/_header';

@media (min-width: $screen-md-min) { /* 992px */
	.c-layout-header.c-layout-header-4 {  

		&.c-bordered { 
			border-bottom: 1px solid rgba(#000000, 0.05);   
		}     
	}
}  