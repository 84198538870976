// Quick Sidebar

$quick-sidebar_z-index:															10000;
$quick-sidebar_width: 															275px;
$quick-sidebar_bg-color:														rgba(#2f353b, 1);
$quick-sidebar_general-font-color:  											lighten(#2f353b, 50%);  
$quick-sidebar_general-font-color-on-hover:										lighten($quick-sidebar_general-font-color, 5%);


// BEGIN: General Mode

.c-layout-quick-sidebar { 
	ddisplay: block;
	width: $quick-sidebar_width;
	background: $quick-sidebar_bg-color;
	z-index: $header_zindex + 1;
	position: fixed;
	top: 0;
	bottom: 0;
	right: -$quick-sidebar_width;
	transition: right 0.2s;
	overflow-x: auto;

	.c-layout-quick-sidebar-shown & {
		right: 0;
		transition: right 0.2s;
	}
  
	> .c-header {
		padding: 15px;
		text-align: right;

		> .c-close {
			> i {
				color: $quick-sidebar_general-font-color;
			}

			&:hover {
				> i {
					color: $quick-sidebar_general-font-color-on-hover;
				}				
			}
		}
	}  

	> .c-content {	
		> .c-section {
			margin: 30px;

			&:first-child {
				margin-top: 10px;
			}

			> h3 {
				font-size: 13px;
				color: $quick-sidebar_general-font-color;
				margin: 5px 0;
				font-weight: 600;
				text-transform: uppercase;
			}

			> .c-settings {
				@include clearfix(); 
				margin: 10px 0;

				> .btn {
					padding-left: 9px;
					padding-right: 9px;
					min-width: 91px;
					text-align: center;

					&:first-child {
						margin-right: 8px;
					}
				}

				> .c-color {
					display: inline-block;
					height: 29px;
					width: 29px;
					margin: 0 0px 0px 0; 
					border: 3px solid $quick-sidebar_bg-color;
					border-radius: 20px !important;

					&.c-active {
						border: 3px solid lighten($quick-sidebar_bg-color, 15%);
					}

					&:hover {
						cursor: pointer;
					}

					@each $name, $color in $theme-colors {
						&.c-#{$name} {  
							background: $color;
						}
					}
				}
			}
		}
	}
}

// END