// Product 5

// BEGIN: General Mode

.c-content-product-5 {
	@include reset-padmar(); // call mixing reset default padding and margin

	h3 {
		@include reset-padmar();
	}

	.c-title {

		&.c-margin-tb-30 {
			line-height: 1.5em;
			margin: 30px 0;
		}
	}

	.c-line {
		padding: 15px 0;
		border-top: 1px solid rgba(#ffffff, 0.4);
		border-bottom: 1px solid rgba(#ffffff, 0.4);
	}

	.c-detail {
		position: absolute;
		bottom: 0;
		padding: 30px 45px;

		.c-desc {
			margin: 10px 0;
		}

		.btn {
			float: right;
			margin: 30px 0;
		}
	}

	.c-wrapper {

		&.c-center-vertical {
			padding: 15% 0;
		}
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-xs-max) {
	/* 767px */

	.c-content-product-5 {
		.c-detail {
			padding: 10px 10px;
		}
	}
}

// END