.c-content-loader {
    display: none;
    width: 40px;
    height: 40px;
    margin-top:-20px;
    margin-left:-30px;
    background: #ccc;  
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    border-radius: 100%;
    -webkit-animation: c-scaleout 1.0s infinite ease-in-out;
    animation: c-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes c-scaleout {
    0% { -webkit-transform: scale(0.0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
}

@keyframes c-scaleout {
    0% {
        transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}