// FEATURES 14

.c-content-feature-14{
	padding:80px 0;
	overflow:auto;

	img{
		max-width:100%;
	}

	.c-feature-14-title{
		margin-top:4em;
	}

	.c-feature-gallery{
		margin:20px 0;
	}
	
}

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-feature-14{
		padding:60px 0;
		border-bottom:1px solid #eee;

		&:last-child{
			border-bottom: none;
		}

		img{
			display:block;
			margin:0 auto;
		}

		.c-feature-14-title{
			margin-top:0;
		}

		.c-feature-14-btn {
			text-align: center;
			margin-bottom: 20px;

			a{
				margin:0 0 10px 0;
			}
		}
	}

}