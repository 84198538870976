// FEATURE 16

.c-content-feature-16{

	.c-feature-16-container{
		padding:80px 60px;

		.c-feature-16-line{
			width:3em;
			height:1px;
			margin-bottom: 20px;
		}
		.c-feature-16-title{
			margin-bottom: 30px;
		}
		.c-feature-16-desc{
			margin-bottom: 30px;
		}
	}
}