// COUNTER 1
 
// BEGIN: General Mode

.c-content-counter-1 {
 
 	> .row {

 		> div {
 			text-align: center;

 			> .c-title {
 				font-weight: 300;
 				font-size:20px;
 				color: $general-head-font-color;
 				margin: 20px 0 15px 0;
 			}

 			> .c-counter {
 				font-size: 45px;
 				font-weight: 300;
 				color:#32c5d2;
				margin: 10px auto 30px auto;
				background-color: #fff;
				width: 200px;
				height: 200px;
				padding: 65px 0;
				display: block;
				border-radius: 200px;
				border: 1px solid transparent;

				&.c-bordered {
					border: 4px solid transparent;  
					background: none;
				}
 			}

 			> .c-content{
 				color:$general-font-color;
 				padding: 0 30px;  
 			}
 		}
 	}
}
// END


// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-counter-1 {

		> .row {

			> div {

				> .c-title{
					margin-top: 5px;
				}

				> .c-first {
					margin-top: 0;
				}

				> .c-content {
					margin-bottom: 60px;
				}
			}
		}
		
	}
}

// END