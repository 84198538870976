$breadcrumbs-bg-color: 								#f7fafb;
$breadcrumbs-page-title-font-color: 				#000000;
$breadcrumbs-page-breadcrumbs-link-font-color: 		#7f8c97;
$breadcrumbs-border-color: 							darken($breadcrumbs-bg-color, 1%);



// BEGIN GENERAL MODE

// Base markup
.c-layout-breadcrumbs-1 {
	@include clearfix();
	padding: 25px 0;
	background: $breadcrumbs-bg-color;
	margin-top: 100px;

	.c-page-title {
		@include inline-block();

		h3 {
			color: $breadcrumbs-page-title-font-color;
			margin: 10px 0 6px 0;
			font-weight: 500;
			font-size: 18px;
			letter-spacing: 1px;
		}

		h4 {
			color: $breadcrumbs-page-breadcrumbs-link-font-color;  
			margin: 5px 0 5px 0;
			font-weight: 500;
			font-size: 15px;
			letter-spacing: 1px;
		}
	}

	.c-page-breadcrumbs {
		@include inline-block();
		@include reset-padmar();  
		list-style-type: none;

		> li {
			display: inline-block;
    		margin: 0;
    		padding: 8px 4px 8px 4px; 

			&,
    		> a {
				color: $breadcrumbs-page-breadcrumbs-link-font-color;
				font-size: 16px;
				font-weight: 400;
    		}
		}

		&.c-pull-right {
			> li:last-child {
				padding-right: 0;
			}
		}

		&.c-pull-left {
			> li:first-child {
				padding-left: 0;
			}
		}
	}

	&.c-bordered {
		border-bottom: 1px solid $breadcrumbs-border-color;
	}

	&.c-bordered-both {
		border-top: 1px solid $breadcrumbs-border-color;
		border-bottom: 1px solid $breadcrumbs-border-color; 
	}

	&.c-subtitle {
		padding: 30px 0;
		.c-page-breadcrumbs {
			margin-top: 10px;
		}
	}

	&.c-bgimage {
		padding: 80px 0;

		.c-page-breadcrumbs {
			margin-top: 10px;
		}
	}

	&.c-bgimage-full {
		padding: 200px 0 100px;

		.c-breadcrumbs-wrapper {
			background: rgba(#000, 0.2);
			padding: 10px 0;
			margin-bottom: 30px;
		}

		.c-page-breadcrumbs {
			margin-top: 10px;
		}

		&.c-centered {
			text-align: center;

			.c-breadcrumbs-wrapper {
				background: rgba(#000, 0.2);
				padding: 20px 0;
			}

			.c-page-breadcrumbs {
				display: block;
				float: none;
				@include clearfix();

				> li:first-child {
					padding-left: 0;
				}
			}

			.c-page-title {
				display: block;
				float: none;
				@include clearfix();
				margin-bottom: 10px;
			}
		}
	}
}

//	BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-breadcrumbs-1 {
		padding: 10px 0;
		margin-top: 65px;

		.c-page-title > h3 {
			margin: 6px 15px 6px 0;
		}

		.c-page-breadcrumbs{
			clear:both;
			float: left !important;
			text-align: left;
			clear:both;

			> li { 
				padding: 6px 4px 6px 4px; 

				&:first-child{
					padding-left: 0;
				}
			}
		}

		&.c-bgimage-full {
			padding: 40px 0;

			.c-layout-header-fullscreen & {
				padding-top: $header_mobile-height + 40;
			}

			.c-breadcrumbs-wrapper {
				background: rgba(#000, 0.1);
				padding: 10px 0;
				margin-bottom: 10px;
			}

			.c-page-breadcrumbs {
				margin-top: 10px;
			}
		}
	}
}

// END

//	BEGIN: Mobile SM Mode

@media (max-width: $screen-xs-max) { /* 767px */
	.c-layout-breadcrumbs-1 {
		margin-top: 65px;

		.c-page-title {  
			display: block;
			@include clearfix();
			float: left;
			text-align: left;
		}

		.c-page-breadcrumbs {
			display: block;
		}
	}
}

// END 