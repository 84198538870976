// Header 
$header_class:																	'dark-mobile';
$header_mobile-bg-color:														#394048;
$header_mobile-border:															0; 
$header_min_mobile_shadow:														0;
$header_min_mobile_border:														0;

// Brand
$brand_mobile-link-font-color:  												darken(#ffffff, 10%); 
$brand_mobile-link-font-color-on-hover:  										lighten($brand_mobile-link-font-color, 10%);
$brand_mobile_toggler_bg-color:													darken($header_mobile-bg-color, 5%);
$brand_mobile_toggler_line-bg-color:											lighten($header_mobile-bg-color, 35%);

// Quick Search
$quick-search_mobile-input-font-color:  										#fff;  
$quick-search_mobile-input-placeholder-color:									lighten($quick-search_mobile-input-font-color, 10%);
$quick-search_mobile-icon-font-color:											lighten($quick-search_mobile-input-font-color, 10%);  

@import  'theme/_header-mobile';    

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header.c-layout-header-dark-mobile.c-header-transparent-dark {
  		background: rgba(#1e242a, 0.9);

  		&.c-mega-menu-shown {
  			background: $header_mobile-bg-color;
  		} 

  		.c-topbar {
	 		background: none;
		}
	} 
}