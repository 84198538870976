// OSWALD - PRICING 1
 
// BEGIN: General Mode

.c-content-pricing-1 {
	@include reset-padmar(); // call mixing reset default padding and margin
	width: 100%;

	> .c-tile-container {
		width:100%;
		position:relative;
		text-align:center;
 
		> .c-tile {
			background: #ffffff; 
			width:31.9%;
			height:100%;
			float:left;
			padding: 90px 0;
			margin:0 20px;

			&.c-bordered {
				border: 3px solid transparent;
			}

			&.c-shadow {
				box-shadow: 0px 3px 18px -5px rgba(0,0,0,0.5);
			}

			> .c-label {
				color: #fff;
				padding: 4px 10px 2px 10px;
				font-weight:bold;
				font-size: 14px;
				text-transform: uppercase;  
				display:inline-block;
				margin: 0 0 30px 0;
			}

			> p {
				font-size:15px;
				font-weight: 400;
			}

			> .btn {
				margin-top:40px;
			}

			> .c-price{
				color: $general-head-font-color;
				font-size:60px;
				font-weight:bold;

				> sup{
					font-size:28px;
					font-weight:normal;
					vertical-align: super;
				}
			}

		}

		> .c-tile-small {
			margin:40px 0;
			padding:50px 0;

		}
	}

	&.c-option-2 {

		> .c-tile-container {

			> .c-tile {
				> .c-label {
					color: #ffffff;
				}

				&.c-highlight {
					background-color: #32c5d2;

					> .c-label {
						background-color: #ffffff;
						color: #32c5d2;
					}

					> p {
						color:#ffffff;
					}

					> .c-price {
						color: $general-head-font-color;

						> sup {
							color: $general-head-font-color;
						}
					}			
				}
			}			
		}
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* <= 991px */
	.c-content-pricing-1 {

		>.c-title{

			>h3{
				margin:20px 0 50px 0;
			}
		}

		>.c-tile-container{

			>.c-tile{
				width:100%;
				margin:20px 0;
			}
		}
		
	}
}