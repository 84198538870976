// SHOP - PRODUCT DETAILS 1 & 2

.c-shop-product-details-2{

	.c-product-gallery {
		margin: 0 auto;
		width: 100%;
		overflow: hidden;

		>.c-product-gallery-content{
			background: #fff;
			position: relative;
			width: 100%;
			height: 600px;
			overflow: hidden;

			img{
				width: 100%;
			}

			> .c-zoom{
				position: relative;
				width: 100%;
				height: 600px;
				overflow: hidden;
			} 
		}

		>.c-product-gallery-thumbnail{
			margin-top: 10px; 

			>.c-product-thumb{
				cursor: pointer;
				padding:0 5px;
				margin-bottom:10px;
				overflow:hidden;

				img{
					width: 100%;
				}

				&.c-left-thumb{
					padding-left:0;
				}

				&.c-right-thumb{
					padding-right:0;
				}

			}
		}
	}

	&.c-opt-1{
		.c-product-gallery-content{
			height: 520px;
			overflow: hidden;

			> .c-zoom{
				height: 520px;
			} 
		}
	}

	.c-product-meta{

		>.c-content-title-1{
			float:left;
		}

		>.c-product-badge{
			text-align:right;

			>.c-product-new{
				float:right;
				padding:5px 10px;
				background-color:#32c5d2;
				color:#ffffff;
			}

			>.c-product-sale{
				float:right;
				padding:5px 10px;
				background-color:#eb5d68;
				color:#ffffff;
			}
		}

		>.c-product-review{
			clear:both;
			margin-bottom:2em;

			>.c-product-rating{
				display:inline-block;
				padding-right:1em;
				border-right:1px solid;
				border-color:#cccccc;
			}

			>.c-product-write-review{
				display: inline-block;
				padding-left:1em;
			}
		}		

		>.c-product-price{
			font-size:28px;
			font-weight:600;
			margin-bottom:1em;
		}

		>.c-product-short-desc{
			margin-bottom: 1em;
		}

		>.c-product-variant{

			select{
				border-color: #d0d7de;
    			padding: 6px;
			}
		}

		.c-product-meta-label{
			float:left;
			margin-right:1em;
			margin-top:0.25em;
		}

		.c-product-margin-1{
			margin-right:33px;
		}

		.c-product-margin-2{
			margin-right:36px;
		}
	}

}

// END 

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-product-details-2{

		.c-product-gallery {

			>.c-product-gallery-content{
				height: 460px;

				> .c-zoom{
					height: 460px;
				} 
			}
		}

		.c-product-meta{
			margin-top:1.5em;
		}
	}
}