//	BEGIN: General Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-revo-slider-6 {

		.c-main-title {
			margin-top: -80px;
			padding: 20px;  
			font-size: 20px;
			line-height: 24px;
		}

		.c-sub-title {
			padding: 15px 20px;
			line-height: 22px;
			font-size: 13px;
		}

		.c-action-btn.btn,
		.c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
			padding: 7px 22px 5px 22px;
			font-size: 14px;    			
			margin-bottom: -60px;
		}
	}
}