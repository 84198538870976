// SHOP REGISTER LOGIN PAGE 1

.c-shop-login-register-1 {
	@include reset-padmar();

	.c-content-divider > span {
		display: table;
	}

	.c-panel {
		border-color: #ebebeb;
	}

	.c-panel-body {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.c-margin-fix {
		margin-bottom: 3px;
	}
}

// END

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-login-register-1 {
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */
	.c-shop-login-register-1 {
	}
}