// CART MENU DROPDOWN

.c-layout-header {

	.c-cart-toggler-wrapper {
		.c-btn-icon {
			.c-page-on-scroll & {
				margin-bottom: 3px !important;
			}

			i {
				font-size:20px !important;
			}			
		}

		.c-cart-number{
			margin-left: 5px;
			position: relative;
			top: -3px;  
			display: inline-block;
			border-radius: 20px;
    	   	text-align: center;
    	   	padding: 3px 0;
	    	height: 25px;
	    	font-size: 15px;
	    	width: 25px;
    		color: #ffffff;
		}
	}

	// shopping cart
	.c-cart-menu{
		border: 1px solid #f3f3f3;
		display:none; 
		width:390px;
		background: #ffffff;
		position:absolute;
		top:100%;
		right:0;

		.c-header-cart-shown & {
			display: block;
		}

		.c-cart-menu-title{
			padding: 20px;

			>.c-cart-menu-float-l{
				float:left;
				display:inline-block;
			}

			>.c-cart-menu-float-r{
				float:right;
				display:inline-block;
			}
		}

		.c-cart-menu-items{
			padding:0;
			max-height: 500px;
			overflow-y:auto;
			width:100%;

			>li{
				border-top:1px solid #f3f3f3;
				padding: 20px;
				width:100%;
				list-style: none;
				overflow:auto;

				>img{
					width:80px;
					height:80px;
					float:left;
					margin-right:20px;
				}

				> .c-cart-menu-content{
					padding-left:100px;
					.c-item-name,
					.c-item-price {
						line-height:1em;
					}
				}

				> .c-cart-menu-close{
					float:right; 
					margin-top: 10px;  
					> a { 
						font-size: 28px;
						font-family: arial;
						font-weight: 100;
						color: #ddd;
					}
				}
			}
		}

		.c-cart-menu-footer{
			border-top:1px solid #f3f3f3;
			padding: 20px;
			text-align:center;
		}
	}

	.container-fluid {
		.c-cart-menu {
			right: 20px;
		}
	}
}

// MOBILE

@media (max-width: $screen-sm-max){ /* 991px */
	.c-layout-header{
		.c-cart-toggler-wrapper{	
			display:none !important;
		}
	}

	.c-layout-header {
		.c-brand {				
			> .c-cart-toggler {
				width: auto;
				height: auto;

				i {
					display: inline-block;
					font-size:18px !important;  
					position: relative;
					top: 3px;
				}	

				.c-cart-number{
					margin-left: 3px;
					position: relative;
					top: 0px;  
					display: inline-block;
					border-radius: 20px;
		    	   	text-align: center;
		    	   	padding: 2px 0;
			    	height: 22px;
			    	font-size: 13px;
			    	width: 22px;
		    		color: #ffffff;
				}
			}
		}
	}

}

@media (max-width: $screen-xs-max){  
	.c-layout-header{

		.c-cart-menu{
			width:100%;
			right: 0;

			.c-cart-menu-items{
				max-height: 150px;
			}
		}

		.container-fluid {
			.c-cart-menu {
				right: 0;
			}
		}
	}

}
