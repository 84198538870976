// BEGIN: Media 2

.c-content-media-2 {
	padding:30px;
	position:relative;
	background-repeat: no-repeat;
	background-size: cover;

	.c-panel {
		position: absolute;
		bottom: 10px;
		right: 30px;

		> .c-fav {
			font-size:20px;
			color:#ffffff;

			> i{
				position: relative;
				top: 2px;
				margin-right: 10px;
				display:inline-block;

				&:hover{
					color:#32c5d2;
					cursor: pointer;
				}
			}

			> p{
				display: inline-block;
			}
		}
	}
}

.c-content-media-2-slider {
	.c-content-label {
		position: absolute;
		top: 25px;
		left: 40px;
		z-index: 10;
	}

	.owl-controls {
		margin: 0;
	  	text-align: right;
	  	position: absolute;
	  	top: 30px;
	  	right: 30px;
	}

	&.c-pagination-bottom {
		.owl-controls {
			top: auto;
			bottom: 20px;
	  		right: 30px;
	  	}
	}
}

// END: Media 2

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-media-2 {
		margin-bottom: 20px;
	}

	.c-content-media-2-slider {
		margin-bottom: 20px;
	}
}

// END