// Feature 2
 
// BEGIN: General Mode

.c-content-feature-2 {
	padding:40px 40px 40px 40px;
	margin-bottom: 20px;
	background: #ffffff;

	.c-icon-wrapper {
		width:70px;
		height:70px;
		padding:10px 0 10px 0;
		float:left;
		margin-right: 25px;

		> .c-content-line-icon {	
			width:60px;
			height:60px;
			float:left;
		}
	}

	.c-title {
		font-size:20px;
		color: $general-head-font-color;
		margin-top:0;
		font-weight: 500;
		text-transform: uppercase;
	}

	p {
		font-size:17px;
		color: $general-font-color;
		font-weight: 300;
		padding-left:95px;
		margin: 0;
	}

	&.c-option-2 {
		cursor: pointer;
		padding: 20px 0;

		.c-icon-wrapper {
			background: none;
			padding:15px;
			border:1px solid #eeeeee;

			> .c-content-line-icon {	
				width:40px;
				height:40px;
				float:left;
			}		
		}

		&:hover {
			.c-icon-wrapper {
				> .c-content-line-icon {
					background-image: url(../img/content/line-icons/white.png);
				}

				border: 0;
			}
		}
	}
}

.c-content-feature-2-grid {
	.c-content-v-center {
		margin-bottom: 20px;
	}
}