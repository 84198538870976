// BEGIN: Mega Menu

$mega-menu_class:																'light-mobile';
$mega-menu_mobile_shadow:														0px 3px 9px -4px rgba(0,0,0,0.25);

$mega-menu_mobile_bg-color:                      								#ffffff; 
$mega-menu_mobile_link-border-color:											#f7f7f7;	

$mega-menu_mobile_primary-link-font-color:            							$general-head-font-color;
$mega-menu_mobile_primary-link-font-color-on-active:     						#ff6b57;

$mega-menu_mobile_inner-dropdown-menu-head-color:         						$general-head-font-color;
$mega-menu_mobile_inner-dropdown-menu-link-color:                   			lighten($general-head-font-color, 5%);   
$mega-menu_mobile_inner-dropdown-menu-link-color-on-active:         			#ff6b57;

@import  'theme/mega-menu-mobile';

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile {
  		box-shadow: $mega-menu_mobile_shadow;
	}
}