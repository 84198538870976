// BEGIN: Media 1

.c-content-media-1 {
	padding: 30px;
	background-color: #ffffff;
	margin: 0;
	position:relative;

	&.c-bordered {
		border: 1px solid #f0f1f2;
	}

	.c-content-label {
		padding: 4px 10px 2px 10px;
	}

	.c-title {
		display: block;
		font-size:30px;
		font-weight:300;
		line-height: 35px;
		color: $general-head-font-color;
		margin:30px 0 20px 0;
	}  

	p {
		font-size:19px;
		color:$general-font-color;
		font-weight:300;
		line-height: 24px;
	}

	.c-date {
		margin-bottom: 10px;
		margin-top: 30px;
		display: inline-block;
		color:$general-font-color;
		font-size:16px;
		text-transform: uppercase;
		font-weight: 500;
	}

	.c-author {
		overflow:auto;
		margin-bottom: 10px;
		margin-top: 30px;

		> .c-portrait {
			position: relative;
			background-size:cover;
			width:55px;
			height:55px;
			border-radius: 58px;
			float:left;
			margin-right:20px;
		}

		> .c-name {
			float:left;
			font-size:16px;
			font-weight: 400;
			color:$general-font-color;
			margin:15px 0 0 0;
		}

		> p {
			font-size:12px;
			color:#32c5d2;
			margin: 0;
		}
	}

	.c-action {
		margin-top: 20px;
		text-align: right;
	}
}

.c-content-media-1-slider {
	> .c-content-label {
		padding: 4px 10px 2px 10px;
		position: absolute;
		top: 30px;
		left: 40px;
		z-index: 10;
	}

	.owl-controls {
		margin: 0;
	  	text-align: right;
	  	position: absolute;
	  	top: 30px;
	  	right: 30px;
	}

	&.c-pagination-bottom {
		.owl-controls {
			top: auto;
			bottom: 20px;
	  		right: 30px;
	  	}
	}
}


// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-media-1 {
		margin-bottom: 20px;
	}

	.c-content-media-1-slider {
		margin-bottom: 20px;
	}
}

// END