// BEGIN: Mega Menu

$mega-menu_class:																'dark-mobile';

$mega-menu_mobile_bg-color:                      								#786a00; 
$mega-menu_mobile_link-border-color:											lighten($mega-menu_mobile_bg-color, 3%);	

$mega-menu_mobile_primary-link-font-color:            							#fff;
$mega-menu_mobile_primary-link-font-color-on-active:     						#ff6b57;

$mega-menu_mobile_inner-dropdown-menu-head-color:         						darken($mega-menu_mobile_primary-link-font-color, 10%);
$mega-menu_mobile_inner-dropdown-menu-link-color:                   			darken($mega-menu_mobile_primary-link-font-color, 15%);     
$mega-menu_mobile_inner-dropdown-menu-link-color-on-active:         			#ff6b57;

@import  'theme/mega-menu-mobile';

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-btn {
  		border-color: darken($mega-menu_mobile_primary-link-font-color, 10%);
  		color: darken($mega-menu_mobile_primary-link-font-color, 10%);

  		&:focus,
  		&:active,
  		&.active,
  		&:hover {  
  			color: $mega-menu_mobile_bg-color;
  			background: #ffffff;
  		}
	}  
}   