// Bar 1
 
// BEGIN: General Mode

.c-content-bar-1 {
	@include reset-padmar(); // call mixing reset default padding and margin
	width: 100%;

  	text-align:center;

	&.c-align-left {
		text-align:left;
	}

	> h3{
		font-size:30px;
		color: $general-head-font-color;
		font-weight:500;
		margin-bottom:30px;
	}

	> p {
		font-size:16px;
		font-weight: 400;
		margin-bottom:30px;
		color: $general-font-color;
	}

	&.c-bordered {
		border: 3px solid transparent;
		padding: 20px;
	}
}

// END