// BEGIN: Mega Menu	


$mega-menu_mobile_primary-link-font-size:            							15px;
$mega-menu_mobile_primary-link-font-uppercase-size:    							13px;

$mega-menu_mobile_inner-dropdown-menu-head-font-size:         					15px;
$mega-menu_mobile_inner-dropdown-menu-head-font-uppercase-size:         		13px; 
$mega-menu_mobile_inner-dropdown-menu-link-font-size:							14px;
$mega-menu_mobile_inner-dropdown-menu-link-uppercase-font-size:					13px;

$mega-menu_mobile_1st-level-link-padding:										10px 20px 10px 20px;
$mega-menu_mobile_2nd-level-link-padding:										10px 20px 10px 40px;
$mega-menu_mobile_3rd-level-link-padding:										10px 20px 10px 60px;
$mega-menu_mobile_4th-level-link-padding:										10px 20px 10px 80px;
$mega-menu_mobile_5th-level-link-padding:										10px 20px 10px 100px;

$header_mobile_mega-menu-btn-margin: 											15px 15px 5px 15px; 
$header_mobile_mega-menu-quick-sidebar-toggler-padding:							44px 0 41px 15px;

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header {
		//	Navbar
		.c-navbar {
			// Main markup
			.c-mega-menu {
				@include inline-block();   
				@include reset-padmar();
				padding: 5px 10px;
				display: none;
				width: 100%;
				float: none !important;
				overflow-x: hidden;  
				transition: all $header_transition;  

				&.c-shown {
					display: block;
					position: absolute;
					z-index: $zindex-navbar + 1;
				}

				> .nav.navbar-nav { 
					margin: 0 -15px !important;
					float: none;

					> li {
						display: block;  
						float: none; 

						// general link
					    > .c-link {			    	
					    	padding: $mega-menu_mobile_1st-level-link-padding;
							font-size: $mega-menu_mobile_primary-link-font-size;
							letter-spacing: 1px;     

							&:focus,
							&:hover { 
								background: none;
							}
					    }

						// button
						> .c-btn {
							margin: $header_mobile_mega-menu-btn-margin;
						}

						// quick search toggler
						> .c-search-toggler {
							display: none;
						}

						// quick sidebar toggler
						> .c-quick-sidebar-toggler {
							padding: $mega-menu_mobile_1st-level-link-padding;
							background: 0;
							border: 0;
							outline: none;
							padding-right: 0;

							> .c-line { 
								width: 15px;
								display: block;
								height: 2px;
								padding: 0px;
								margin: 3px 0;  

								&:first-child {
									margin-top: 3px;
								}
							}
						}

						// dropdown menu open state
					    &.c-open {
							> .dropdown-menu {
								display: block;		

								.dropdown-submenu.c-open {		
									> .dropdown-menu {
										display: block;		
									}
								}				
							}
						}

						.dropdown-submenu > a > .c-arrow,	
						a.dropdown-toggle > .c-arrow {
							position: absolute;
							right: 15px;
							padding: 3px 0;
							display: inline-block;
							font-size: 13px;
							font-family: FontAwesome;
							height: auto;
							margin-top: -3px;
							text-align: center;
							width: 30px;
							font-weight: 300; 

							&:before {
								content: "\f104";
							}
					    }

						// link arrows
						&.c-open.dropdown-submenu > a > .c-arrow:before,
					    &.c-open > a.dropdown-toggle > .c-arrow:before,
						.c-open.dropdown-submenu > a > .c-arrow:before,
					    .c-open > a.dropdown-toggle > .c-arrow:before {
					    	content: "\f107";
					    }  

						// general dropdown menu
						.dropdown-menu {		
							padding-bottom: 0;			
							float: none;  
							display: none;
							position: static;
							background: none;
							box-shadow: none;
							min-width: none;

							> li {
								> h3 {
									margin: 0;
									font-size: $mega-menu_mobile_inner-dropdown-menu-head-font-size; 
								} 
									
								> a {
									font-size: $mega-menu_mobile_inner-dropdown-menu-link-font-size;
									white-space: normal; 
									
									&:focus,
									&:hover { 
										background: none;
									}
								}
							}
						}

						// classic dropdown menu
						> .dropdown-menu.c-menu-type-classic {
							padding: 0;

							> li > a,					
							> .dropdown-submenu > a {
								padding: $mega-menu_mobile_2nd-level-link-padding;
							}

							> .dropdown-submenu > .dropdown-menu > li > a {
								padding: $mega-menu_mobile_3rd-level-link-padding;
							}

							> .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > a {
								padding: $mega-menu_mobile_4th-level-link-padding;
							}

							> .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > a {
								padding: $mega-menu_mobile_5th-level-link-padding;
							}
						}
						
						// mega dropdown menu
						> .dropdown-menu.c-menu-type-mega {
							padding-top: 0;
							min-width: 0 !important;

							// mega menu general dropdown menu
							.dropdown-menu {
								display: block;

								&.c-menu-type-inline {
									margin-left: 0;

									> li > h3 {
										padding: $mega-menu_mobile_2nd-level-link-padding;
									}

									> li > a { 
										padding: $mega-menu_mobile_3rd-level-link-padding;
									}
								}
							}

							// mega menu sub dropdown menu
							> .dropdown-submenu { 

								> a {
									padding: $mega-menu_mobile_2nd-level-link-padding;
								}

								> .dropdown-menu {
									display: none;

									.dropdown-menu > li > h3,
									.dropdown-menu > li > a {
										padding: $mega-menu_mobile_3rd-level-link-padding; 
									}
								}

								&.c-open {
									> .dropdown-menu {
										display: block;
									}
								}
							}
						}				
					}

					// visible on mobile mode

					.c-visible-desktop {
						display: none !important;
					}
				}
			}

			// Font options		
			.c-mega-menu {

				// Uppercase font
				&.c-fonts-uppercase > .nav.navbar-nav > li {
					// general link
					> .c-link {
						font-size: $mega-menu_mobile_primary-link-font-uppercase-size;
						text-transform: uppercase; 
					}

					// general dropdown menu
					.dropdown-menu {
						> li {
							> h3 {
								font-size: $mega-menu_mobile_inner-dropdown-menu-head-font-uppercase-size;
								text-transform: uppercase; 
							}
									
							> a {
								font-size: $mega-menu_mobile_inner-dropdown-menu-link-uppercase-font-size;
								text-transform: uppercase; 
							}
						}
					}
				}

				// Bold font
				&.c-fonts-bold > .nav.navbar-nav > li {
					// general link
					> .c-link {  
						font-weight: 400;
					}

					// general dropdown menu
					.dropdown-menu {
						> li {
							> h3 {
								font-weight: 400;
							}
									
							> a {
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}

// END
