// Feature 4
 
// BEGIN: General Mode

$block-height: 750px;
$block-height-mobile: 350px;

.c-content-feature-4 {

	.c-content-area {
		width:40%;
	}

	.c-feature-bg {
		width:60%;
	}

	.c-feature-bg,
	.c-content-area {
		height:$block-height;
		margin-bottom: -#{$block-height};
	}

	.c-content-left{
		float: left;

		&.c-semi-circle{
			border-top-right-radius: 35% 50%;
			border-bottom-right-radius: 35% 50%;
		}

		&.c-diagonal{
			border-right: 200px solid;
			border-top: $block-height solid transparent;
		}

		&.c-arrow{
			border-right: 200px solid;
			border-top: $block-height/2 solid transparent;
			border-bottom: $block-height/2 solid transparent;
		}
	}

	.c-content-right{
		float:right;

		&.c-semi-circle{
			border-top-left-radius: 35% 50%;
			border-bottom-left-radius: 35% 50%;
		}

		&.c-diagonal{
			border-left: 200px solid;
			border-top: $block-height solid transparent;
		}

		&.c-arrow{
			border-left: 200px solid;
			border-top: $block-height/2 solid transparent;
			border-bottom: $block-height/2 solid transparent;
		}
	}

	.c-feature-content {
		padding: 0;
		width:30%;

		&.c-left{
			float:left;  
			position: relative;
		}

		&.c-right{
			float:right;
			position: relative;
		}

		.c-content-v-center {
			height: $block-height;
		}

	}

}
// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-feature-4 {
		height:auto;
		margin-bottom: 0;

		.c-content-area{
			width:0px;
		}

		.c-feature-bg{
			width:100%;
			height:$block-height-mobile;
			margin-bottom: -#{$block-height-mobile};
		}

		.c-content-left{
			float: left;

			&.c-semi-circle{
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			
			&.c-diagonal{
				border-right: 0;
				border-top: 0;
			}

			&.c-arrow{
				border-right:0;
				border-top: 0;
				border-bottom: 0;
			}
		}

		.c-content-right{
			float:right;

			&.c-semi-circle{
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&.c-diagonal{
				border-left: 0;
				border-top: 0;
			}

			&.c-arrow{
				border-left: 0;
				border-top: 0;
				border-bottom: 0;
			}
		}

		.c-feature-content{
			padding:60px 0;
			height:auto;
			width:100%;

			> .c-content-v-center {
				height: auto;
				padding-top:350px;
			}

			&.c-left{
				float:left;
			}

			&.c-right{
				float:right;
			}
		}
	}
}

// END