//	BEGIN: General Mode

.c-layout-revo-slider-11 {	
	
	.c-main-title {
		background: rgba(#ffffff, 0.5); 
		padding: 30px 50px;
		letter-spacing: 1px;
		line-height: 68px;
		display: inline-block;
	}
}

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-revo-slider-11 {

		.c-main-title {
			font-size: 22px;
			line-height: 28px;
		}

		.c-action-btn.btn,
		.c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
			margin-top: 20px;
			padding: 7px 22px 5px 22px;
			font-size: 14px;    
		}
	}
}