// Colors

.c-content-color-demo {
	border: 1px solid #eee;
	margin: 0 0 20px 0;
	cursor: pointer;

	.c-color-view {
		padding: 35px 10px;
		text-align: center;
		font-size: 18px;
	}

	.c-color-info {
		border-top: 1px solid #eee;
		padding: 10px 10px;	
		text-align: center;
	}	
}
