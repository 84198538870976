.c-content-client-logos-slider-1 {
	.item {
		text-align: center; 
		img {
			max-width: 230px;
			@include opacity(0.8);
			transition: all 0.2s;

			&:hover {
				@include opacity(1);
				transition: all 0.2s;
			}
		}
	}
}