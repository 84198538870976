// Containers

.c-content-box {	

	&.c-size-sm {
		padding: 30px 0;
	}

	&.c-size-md {
		padding: 60px 0;
	}

	&.c-size-lg {
		padding: 80px 0;
	}

	&.c-size-xg {
		padding: 100px 0;
	}

	&.c-no-bottom-padding {
		padding-bottom: 0;
	}

	&.c-no-top-padding {
		padding-top: 0;
	}

	&.c-no-padding{
		padding:0;
	}

	&.c-overflow-hide {
		overflow: hidden;
	}
}

// BEGIN: Desktop mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-box {
		> .container-fluid {
			padding: $layout-fluid-padding;
		}

		&.c-size-sm {
			padding: 20px 0;
		}

		&.c-size-md {
			padding: 30px 0;
		}

		&.c-size-lg {
			padding: 40px 0;
		}

		&.c-size-xg {
			padding: 60px 0;
		}
	}
}

// END: Desktop mode