//	BEGIN: General Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-revo-slider-1 {

		.c-main-title {
			font-size: 22px;
			line-height: 28px;
		}

		.c-action-btn.btn,
		.c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
			margin-top: 20px;
			padding: 7px 22px 5px 22px;
			font-size: 14px;    
		}
	}
}