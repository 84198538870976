// BEGIN: Desktop mode

@media (min-width: $screen-md-min) { /* 992px */
	// Base header
	.c-layout-header.c-layout-header-#{$header_class} { 
		background: $header_desktop-bg-color; 
		border-bottom: $header_desktop_border;

		// if cms toolbar is on
		&.cms-toolbar-on {
			margin-top: 46px;
		}

		//	Navbar
		.c-navbar {
			background: $header_desktop-navbar-bg;  

			// Main markup
			.c-mega-menu {
				> .nav.navbar-nav {				
					> li {

						> .c-btn-icon,
						> .c-link {
							color: $mega-menu_desktop_primary-link-font-color; 

							&:hover,
							&:focus {
								color: $mega-menu_desktop_primary-link-font-color; 
							}
						}

						> .c-quick-sidebar-toggler {
							> .c-line { 
								background-color: lighten($mega-menu_desktop_primary-link-font-color, 5%);
							}
						}

						&:focus,
						&:active,
						&:hover {
							> a:not(.btn) {
								color: $mega-menu_desktop_primary-link-font-color-on-hover;
							}

							> button {
								> .c-line {
									background: $mega-menu_desktop_primary-link-font-color-on-hover;
								}
							}
						}  

						&.c-active {
							color: $mega-menu_desktop_primary-link-font-color-on-active;
								
							> a:not(.btn) {
								color: $mega-menu_desktop_primary-link-font-color-on-active;
							}		

							> button {
								> .c-line {
									background: $mega-menu_desktop_primary-link-font-color-on-hover;
								}
							}
						}	
					}
				}      	
			}
		}

		// Quick search
		.c-quick-search {
			> .form-control {
				@include placeholder($quick-search_desktop_input-placeholder-color);  
				color: $quick-search_desktop_input-font-color;
			}

			// close icon
			> span {
				color: $quick-search_desktop_icon-font-color;
			}
		}

	}
	
	.c-page-on-scroll .c-layout-header.c-layout-header-#{$header_class} {
		background: $mega-menu_desktop_min-header-bg-color;
		border-bottom: $header_min_desktop_border;
		box-shadow: $header_min_desktop_shadow; 

		//	Topbar
		.c-navbar {

			.c-mega-menu {
				> .nav.navbar-nav {				
					> li {
						> .c-btn-icon,
						> .c-link {
							color: $mega-menu_desktop_min-header-primary-link-font-color; 

							&:hover,
							&:focus {
								color: $mega-menu_desktop_min-header-primary-link-font-color; 
							}
						}

						> .c-quick-sidebar-toggler {
							> .c-line { 
								background-color: lighten($mega-menu_desktop_min-header-primary-link-font-color, 5%);
							}
						}
					}
				}      	
			}
		}
	}
}

// END