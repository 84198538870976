// Pricing 1

// BEGIN: General Mode

@mixin content-pricing-1-theme-1($type-color, $title-color, $text-color, $dollar-color,
$btn-text-color, $btn-color, $btn-hover, $btn-pressed,
$column-odd-color: null, $column-even-color: null, $content-bg-opacity: 0, $border-color: null, $border-opacity: 0) {

	.c-content {

		&.c-column-odd {
			@if ($column-odd-color != null) {
				@if ($content-bg-opacity > 0) {
					background: rgba($column-odd-color, $content-bg-opacity);
				} @else {
					background: $column-odd-color;
				}
			}
		}

		&.c-column-even {
			@if ($column-even-color != null) {
				@if ($content-bg-opacity > 0) {
					background: rgba($column-even-color, $content-bg-opacity);
				} @else {
					background: $column-even-color;
				}
			}
		}

		> .c-row {
			color: $text-color;
			@if ($border-color != null) {
				@if ($border-opacity > 0) {
					border-top-color: rgba($border-color, $border-opacity);
				} @else {
					border-top-color: $border-color;
				}
			}

			&.c-type {
				color: $type-color;
			}
 
			&.c-title {
				color: $title-color;
			}

			&.c-dollar {
				color: $dollar-color;
			}

			&.c-purchase {
			
			}

			> .fa {
				&.fa-check {
					color: #75c199;
				}
				&.fa-times {
					color: #ff6b57;
				}
			}
		}
	}
}

.c-content-pricing-1 {
	@include reset-padmar(); // call mixing reset default padding and margin

	.c-content {

		&.c-padding-adjustment {
			margin-right: -30px;
		}

		> .c-row {
			padding: 30px;
			text-align: center;
			border-top: 1px solid;
			font-weight: 500;

			> .c-sub-title {
				display: none;
			}

			&:first-child, &:last-child {
				border-top: none;
			}

			&.c-type {
				padding: 30px;
				font-weight: 500;
			}

			&.c-title {
				text-align: left;
				padding-left: 0px;
				font-weight: normal;
			}

			&.c-align-left {
				padding-left: 0px;
				text-align: left;
			}

			&.c-purchase {
				> .c-btn-purchase {
					padding: 12px 30px 10px 30px;
				}
			}
		}
	}

	//$type-color, $title-color, $text-color, $dollar-color,
	//$btn-text-color, $btn-color, $btn-hover, $btn-pressed,
	//$column-odd-color: null, $column-even-color: null, $content-bg-opacity: 0, $border-color: null, $border-opacity: 0
	&.c-opt-1 {
		@include content-pricing-1-theme-1(
				#5f6f77, #84969f, #5f6f77, #b9c4ca,
				#ffffff, #5bd3f5, #33a6cc, #007299,
				null, #fafbfb, 0, #f4f4f4
		);
	}
	&.c-opt-2 {
		@include content-pricing-1-theme-1(
				#fbfefe, #969ab9, #969ab9, #5b5f84,
				#747aa9, #333756, #212541, #16162d,
				null, #ffffff, 0.02, #ffffff, 0.04
		);
	}
}

@mixin c-title-pricing-1($title-color, $title-color-1, $btn-text-color, $btn-color, $btn-hover, $btn-pressed) {
	> h4 {
		color: $title-color;
		@if ($title-color-1 != null) {
			> span {
				color: $title-color-1;
			}
		}
	}

	> .c-try {
		> .c-btn-try {
			background: $btn-color;
			color: $btn-text-color;

			&:hover, &:focus, &:active, &.active, .open > .dropdown-toggle.c-btn-try {
				background: $btn-hover;
			}

			&:active, &.active {
				background: $btn-pressed;
				box-shadow: none;
			}
		}
	}

}

.c-title-pricing-1 {
	margin-top: 40px;

	> h4 {
		float: left;
		margin-top: 0px;
		margin-bottom: 70px;
	}

	> .c-try {
		position: absolute;
		top: 30px;
		right: 15px;
		float: left;

		> .c-btn-try {
			padding: 12px 30px 10px 30px;
		}
	}

	&.c-opt-title-1 {
		@include c-title-pricing-1(#455055, null, #ffffff, #ff6b57, #e16450, #c3604b);
	}
	&.c-opt-title-2 {
		@include c-title-pricing-1(#fbfefe, #41c6ec, #ffffff, #ff6b57, #e16450, #c3604b);
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) {
	/* 991px */

	.c-title-pricing-1 {
		margin-top: 40px;

		> h4 {
			float: left;
			margin-top: 0px;
			margin-bottom: 70px;
		}

		> .c-try {
			position: absolute;
			top: -7px;
			right: 15px;
			float: left;
		}
	}

	.c-content-pricing-1 {

		> .c-sm-hidden {
			display: none;
		}

		.c-content {

			> .c-row {
				padding: 30px;
				text-align: center;

				> .c-sub-title {
					display: inline;
				}

				&.c-align-left {
					padding-left: 30px;
					text-align: center;
				}

				&.c-title {
					text-align: center;
					padding-left: 30px;
				}

				&.c-empty {
					display: none;
				}
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	/* 766px */

	.c-title-pricing-1 {
		text-align: center;
		padding: 0px;

		> h4 {
			width: 100%;
			clear: both;
			margin: 0px auto 30px;
		}

		> .c-try {
			position: static;
			clear: both;
			width: 100%;
			margin-bottom: 50px;

			> .c-btn-try {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.c-content-pricing-1 {

		.c-content {
			margin-bottom: 80px;

			&.c-padding-adjustment {
				margin-left: 0px;
				margin-right: 0px;
			}
		}

	}

}

// END