// COOKIES BAR

.c-cookies-bar-1{
	width:100%;
	height: auto;
	position: fixed;
	left:0;
	right:0;
	top:auto;
	bottom:auto;
	z-index:10000;
	opacity: 0;

	&.c-cookies-bar-top{
		top:0;
	}

	&.c-cookies-bar-bottom{
		bottom:0;
	}

	>.c-cookies-bar-container{
		margin:20px;

		.c-cookies-bar-content{
			margin:5px;
		}

		.c-cookies-bar-btn{
			text-align: right;
		}
	}
}

.c-cookies-bar-2{
	display: block;
	max-width:800px;
	height: auto;
	position: fixed;
	z-index: 10000;
	left:0;
	right:0;
	margin:20px auto;
	opacity: 0;

	&.c-cookies-bar-top{
		top:20px;
	}

	&.c-cookies-bar-top-left{
		left:20px;	
		top:20px;
		right:auto;
	}

	&.c-cookies-bar-top-right{
		right:20px;	
		top:20px;
		left:auto;
	}

	&.c-cookies-bar-bottom{
		bottom:20px;
	}

	&.c-cookies-bar-bottom-left{
		left:20px;
		bottom:20px;
		right:auto;
	}

	&.c-cookies-bar-bottom-right{
		right:20px;
		bottom:20px;
		left:auto;
	}

	>.c-cookies-bar-container{
		margin:20px;

		.c-cookies-bar-content{
			margin:5px;
		}

		.c-cookies-bar-btn{
			text-align: right;
			margin:10px 0;
		}
	}
}

// MOBILE RESPONSIVE
@media (max-width: $screen-sm-max){
	.c-cookies-bar-1{

		>.c-cookies-bar-container{

			.c-cookies-bar-content{
				margin-bottom: 25px;
			}

			.c-cookies-bar-content,
			.c-cookies-bar-btn{
				text-align: center;
			}
		}
	}

	.c-cookies-bar-2{
		margin: 20px;

		&.c-cookies-bar-top-left{
			left:0;	
			top:20px;
			right:0;
		}

		&.c-cookies-bar-top-right{
			left:0;	
			top:20px;
			right:0;
		}

		&.c-cookies-bar-bottom-left{
			left:0;	
			bottom:20px;
			right:0;
		}

		&.c-cookies-bar-bottom-right{
			left:0;	
			bottom:20px;
			right:0;
		}

		>.c-cookies-bar-container{

			.c-cookies-bar-content{
				margin-bottom: 25px;
			}

			.c-cookies-bar-content,
			.c-cookies-bar-btn{
				text-align: center;
			}
		}
	}
}