// SHOP DEMO 1

// HEADER
.c-layout-header.c-layout-header-shop-1{

	.c-shop-topbar-offer{
		position: relative;
		padding:10px;
		display: table;
		width: 100%;
		margin: -20px 0 20px 0;

		.c-shop-topbar-offer-title{
			width: 100%;
			display: table-cell;
			vertical-align: middle;
		}

		.c-shop-topbar-close{
			display: table-cell;
			width:1px;
			vertical-align: middle;
		}
	}

	.c-shop-topbar-level-1{
		margin-top: 20px;

		.c-top-menu {
			display: table;
			width: 100%;
			padding: 10px 0;
			margin: 0;

			>.c-shop-topbar-level-1-desc{
				display: table-cell;
				min-width: 100%;
				padding-right: 20px;
				vertical-align: middle;
				text-align: left;
			}
			>.c-ext{
				display: table-cell;
				width: 65px;
				margin-right: 20px;

				> li {
					
					&.c-lang{
						margin-left:0;
						margin-right:0;
					}

					> a{
						font-size:14px;
						padding:0 0 10px 0;
						height:auto;

						> i {
							top:1px;
						}	
					} 

					.dropdown-menu{
						min-width: auto;
					}

					.c-active{
						background-color: #eee;
					}
				}
			} 
			>.c-shop-topbar-search{
				width:150px;
				display: table-cell;
				font-size: 14px;

				> .c-shop-topbar-search-input{
					width:100%;
					padding: 0 25px 0 5px;

					&:active{
						outline:none;
					}
				}

				> i{
					float: right;
					margin-top: -1.4em;
					position: relative;
					padding-right: 10px;
				}	
			}
		}
	}

	.c-navbar{
		.c-mega-menu-offers-img-set{
			list-style: none;
			margin:0;
			padding:0;
			display:table;
			width: 100%;
			position: relative;

			.c-mega-menu-offers-img{
				width:50%;
				display: table-cell;
				position: relative;

				img{
					width: 100%;
					height: auto;
					transition: all 0.2s; 

					&:hover{
						opacity: 0.8;
					}
				}

				.c-mega-menu-offers-overlay{
					text-align: center;
					padding:10px 15px 15px 15px;
					
					.c-mega-menu-offers-overlay-title,
					.c-mega-menu-offers-overlay-desc{
						margin-bottom: 10px;
						line-height: 1em;
					}

				}
			}
		}

		.c-mega-menu-collections-col{
			width: 20% !important;
		}
	}

	.c-topbar .c-brand > .c-logo:after { background: transparent; }

	.c-float-right{ float:right !important; }

	.c-navbar .c-mega-menu{ text-align: left; }

	.c-mega-menu-offers-mobile{ display: none; }
	.c-mega-menu-offers-desktop{ display: block; }
}


@media(min-width: 1170px){
	.c-layout-header.c-layout-header-shop-1{

		.c-navbar .container-fluid > .c-navbar-wrapper > .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega { 
			right: 0; 
			padding: 0 75px;
		}

	}
}

@media (min-width: $screen-md-min) { /* 992px */
	.c-layout-header.c-layout-header-shop-1{
		.c-topbar{ 
			height: auto;
			padding: 20px 0 0 0;
		}
		.c-navbar .container-fluid > .c-navbar-wrapper > .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega { 
			max-width: 100%; 
			padding: 0 75px;
		}
		.c-layout-page{
			margin-top:200px;
		}
	}

	.c-layout-header-fixed.c-layout-header-mobile-fixed.c-shop-demo-1{
		.c-layout-page{
			margin-top: 257px;
		}
	}
}

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-header.c-layout-header-shop-1{
		.c-float-right{ float:none !important; }
		.c-shop-topbar-level-1{ text-align:left; margin-top: 0; }
		.c-shop-topbar-offer{ margin: 0; }
		.c-mega-menu-offers-mobile{ display: block; }
		.c-mega-menu-offers-desktop{ display: none !important; }
		.c-center{ text-align:left !important }

		.c-navbar{
			.c-mega-menu-collections-col{
				width: 100% !important;
			}
		}

	}

	.c-layout-header-fixed.c-layout-header-mobile-fixed.c-shop-demo-1{
		.c-layout-page{
			margin-top: 208px;
		}
	}
}

@media (max-width: $screen-xs-max) { /* 767px */

	.c-layout-header.c-layout-header-shop-1{
		.c-shop-topbar-offer{
			.c-shop-topbar-offer-title{
				padding-right: 20px;
			}
		}
		.c-shop-topbar-level-1{
			.c-top-menu {
				>.c-shop-topbar-level-1-desc span{
					display: none;
				}
			}
		}
	}

	.c-layout-header-fixed.c-layout-header-mobile-fixed.c-shop-demo-1{
		.c-layout-page{
			margin-top: 211px;
		}
	}
}

