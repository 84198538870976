.c-content-login-form {

	.modal-dialog {
		width: 450px;

		.modal-content {
			margin-top: 125px;	

			.modal-body {
				padding: 10px 40px 30px 40px;

				.form-group {
					margin: 20px 0;
				}

				.c-btn-login {
					padding-left: 40px;
					padding-right: 40px;
				}

				.c-btn-forgot {
					margin-top: 8px;
					float: right;
					display: inline-block;
				}

				.c-content-list-adjusted {
					margin-top: 30px;
				}

				.c-content-divider {
					text-align:center; 

					> span {
						display: inline-block;
				  		background: #fff;
				  		padding: 5px 8px;
				  		position: relative;
				  		top: -1.05em;
				  		margin: auto;
					}
				}
			}

			.modal-footer {
				background: $general-bg-color; 
				padding: 20px 40px;
				text-align: left;

				.c-text-account {
					display: inline-block;
					margin-top: 5px;
				}

				.c-btn-signup {
					float: right;
					padding-left: 30px;
					padding-right: 30px;
				}
			}	
		}
	}
}

@media (max-width: 1390px) {
	.c-content-login-form {
		.modal-dialog {

			.modal-content {
				margin-top: 85px;
			}
		}
	}
}

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-login-form {
		top: 0px;

		.modal-dialog {

			.modal-content {

				.modal-body {
					padding: 10px 20px 10px 20px;

					.form-group {
						margin: 15px 0;
					}

					.c-btn-login {
						padding-left: 15px;
						padding-right: 15px;
					}

					.c-content-list-adjusted > li {
						width: 100%;
						display: block;
						padding: 0;
						margin: 5px 0 0 0;
					}
				}
			}
		}
	}
}


@media (max-width: $screen-sm-min) { /* 768px */
	.c-content-login-form {
		top: 0px;

		.modal-dialog {
			width: auto;
		}
	}
}