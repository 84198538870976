// Card 1
 
// BEGIN: General Mode

.c-content-feature-1 {

	padding-right: 40px;

	> h4 {
		font-size:18px;
		color: $general-head-font-color;
		margin-top:0;
		font-weight: 500;
		text-transform: uppercase;
	}

	p {
		font-size:18px;
		color: $general-font-color;
	}

	> h3 {
		font-size:30px;
		color: $general-head-font-color;
		font-weight:500;
		margin:30px 0 25px 0;
	}
}  

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-feature-1 {
		padding-right: 20px;
	}
}

@media (max-width: $screen-xs-max) { /* <= 767px */

	.c-content-feature-1 {
		padding-right: 0;
		padding-bottom: 30px;
		margin-bottom: 40px;
		border-bottom: 1px solid $general-border-color;
		text-align: center;

		.c-content-line-icon {
			width: 40px;
			height: 40px;
		}

		.c-font-uppercase {
			font-size: 25px;
			margin: 10px 0;
		}

		.c-font-thin {
			font-size: 16px;
		}
	}
}

// END