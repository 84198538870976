.c-content-divider {
	display: block;
	height: 2px;
	background: $general-head-font-color;
	margin: 20px 0;
	position: relative;

	> i {
		color: $general-head-font-color;
		left: 50%;
		display: inline-block;
		position: absolute;
		width: 34px;
		height: 34px;
		text-align: center;
		top: -16px;		
		font-size: 18px;  
		vertical-align: middle;

		&.c-rounded {
			border-radius: 100%;
		}

		&.c-square {
			border-radius: 34px;
		}

		&.icon-dot {
			padding: 0;
			width: 14px;
			height: 14px;
			top: -6px;
			background: $general-head-font-color;
			border-radius: 14px;
		
			&.c-square {
				border-radius: 0;
			}
		}
	}

	> span {
		display: block;
		background: #fff;
		padding: 5px 7px;
		width: auto;
		position: relative;
		top: -18px;
		margin: 0 auto;
	}

	&.c-left {
		margin-left: 25px;
	
		> i {
			left: -25px;
		}
	}

	&.c-right {
		margin-right: 25px;
		
		> i {
			left: auto; 
			right: -25px;
		}
	}

	&.c-icon-bg {
		> i {
			padding-top: 8px;
			width: 35px;
			height: 35px;
			top: -17px;
			border-radius: 35px;
			background: $general-head-font-color;
		}

		&.c-left {
			margin-left: 45px; 
		
			> i {
				left: -45px;
			}
		}

		&.c-right {
			margin-right: 45px;
			
			> i {
				left: auto; 
				right: -45px;
			}
		}
	}

	&.c-divider-sm {
		height: 1px;

		> .icon-dot {
			width: 10px;
			height: 10px;
			top: -4px;
			background: $general-head-font-color;
			border-radius: 10px;
			
			&.c-square {
				border-radius: 0;
			}
		}
	}
}