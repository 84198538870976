// FEATURES 15

.c-content-feature-15{

	.c-feature-15-container{
		padding:60px;
		margin:80px 0;

		.c-feature-15-title{
			margin-bottom: 30px;
			padding-left:15px;
			border-left:3px solid;
		}
		.c-feature-15-desc{
			margin-bottom: 30px;
		}
		.c-feature-15-img{
			width:100%;
			height:auto;
		}
	}
}

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-feature-15{

		.c-feature-15-container{
			
			.c-feature-15-img{
				margin-bottom: 30px;
			}
		}
	}
}