// Product 2

// BEGIN: General Mode

.product-box {
	padding: 0 15px !important;
	.c-content-product-2 {

		min-height: 450px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.c-info {
			padding: 10px 20px;
			min-height: 95px;

			.c-title {
				margin-bottom: 20px;
			}

			.c-price {
				margin-bottom: 0;
			}
		}

		.c-label {
			padding: 5px 15px;
			display: inline-block;
			position: absolute;
			z-index: 9;
			background-color: #63C2CF;

			&.c-label-right {
				right: 0;
				top: 0;
			}
		}

		.c-btn-product {
			border: none;
			color: #a5b0bc;

			&.btn-lg {
				font-size: 16px;
				padding-top: 14px;
				padding-bottom: 13px;
			}

			&.btn-sm {
				font-size: 11px;
				padding-top: 10px;
				padding-bottom: 8px;
			}
		}

		.btn {
			& + .btn {
				margin-left: 5px;
			}
		}
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) {
	/* 991px */

	.product-box {
		.c-content-product-2 {
		}
	}
}

// END
