// WHY CHOOSE US 1

// BEGIN: General Mode

@mixin accordion-variant($active-panel-bg) {
	.panel {
		> .panel-heading {
			> .panel-title {
				> a {
					background-color: $active-panel-bg;

					.c-accordion-bordered & {
						border: 2px solid darken($active-panel-bg, 10%) !important;
					}

					&.collapsed {
						background-color: #fff;  

						&:hover, 
						&:focus {
							color: #fff;						
							background-color: $active-panel-bg;  
						}
					}
				}
			}
		}

		> .panel-collapse {
			> .panel-body {
				background-color: $active-panel-bg;

				.c-accordion-bordered & {
					border: 2px solid darken($active-panel-bg, 10%) !important;
				}
			}
		}
	}
}

.c-content-accordion-1 {

	.panel-group {
		margin-bottom: 0;
	}

	.panel {
		padding: 0;
		box-shadow: none;
		border-radius: 0;
		border: 0;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		> .panel-heading {
			padding: 0;
			color: #ffffff;

			> .panel-title {
				padding: 0;
				margin: 0;

				> a {					
					transition: all 0.2s;
					color:#fff;
					display: block;
					padding: 30px 30px 25px 30px;  
					border-radius: 0;
					box-shadow: none; 

					> i {
						margin-right: 5px;
						font-size: 17px;
					}

					&.collapsed {
						background-color: #fff;
						color: $general-head-font-color;

						&:hover, 
						&:focus {
							color: #fff;						
							transition: all 0.2s;
						}
					}
				}
			}
		}

		> .panel-collapse {
			> .panel-body {
				border-top: none;
				padding: 0px 30px 35px 30px;
				color: #ffffff;
			}
		}
	}

	@each $name, $colors in $component-colors {
		&.c-accordion-#{$name} {
		@include accordion-variant(map-get($colors, color));
		}
	}	
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-md-max) {
	/* 991px */

	.c-content-accordion-1 {
		.c-accordion {
			margin: 0;  
			padding: 0;
		}
	}
}