// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header {

		//	Navbar
		.c-navbar {
			// Main markup
			.c-mega-menu.c-mega-menu-#{$mega-menu_class} {
				background: $mega-menu_mobile_bg-color;

				> .nav.navbar-nav { 
					> li {

						// general link
					    > .c-link {			    	
							border-bottom: 1px solid $mega-menu_mobile_link-border-color;
							color: $mega-menu_mobile_primary-link-font-color;      

							&:focus,
							&:hover {
								background: none;
								color: $mega-menu_mobile_primary-link-font-color;
							}
					    }

						// quick sidebar toggler
						> .c-quick-sidebar-toggler {
							background: none;

							> .c-line { 
								background-color: lighten($mega-menu_mobile_primary-link-font-color, 15%);
							}

							&:hover {  
								> .c-line { 
									background-color: $mega-menu_mobile_primary-link-font-color-on-active;
								}
							}
						}

						// general link states
						&.c-active,
						&:hover {
							> .c-link {	
								color: $mega-menu_mobile_primary-link-font-color-on-active;
								background: none;	  			
							}
						}

						// general dropdown menu
						.dropdown-menu {		

							> li {
								> h3 {
									color: $mega-menu_mobile_inner-dropdown-menu-head-color;
								} 
									
								> a {
									border-bottom: 1px solid $mega-menu_mobile_link-border-color;
									color: $mega-menu_mobile_inner-dropdown-menu-link-color;

									&:focus,
									&:hover {
										color: $mega-menu_mobile_inner-dropdown-menu-link-color;
									}
								}

								&.c-active > a,
								&:hover > a {
									background: none;
									color: $mega-menu_mobile_inner-dropdown-menu-link-color-on-active;  
								} 
							}
						}			
					}
				}
			}
		}
	}
}

// END