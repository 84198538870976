//	BEGIN: General Mode

.c-layout-revo-slider {
	@include clearfix();
	overflow-x: hidden;

	.c-video-hint {
		> i {
			position: relative;
			top: 2px;
			font-size: 14px;      
		}
	}
}