// BEGIN PAGE CONTAINER

.c-layout-page {	
	@include clearfix();	
	> .errorpage {
		margin-top: 200px;
	}
}

.c-layout-page-loading {
	* {
		visibility: hidden;
	}

	.c-content-loader {
		visibility: visible; 
		display: block;
		position: fixed;
		top: 50%;
		left: 50%;
	}
}

// END

@media (min-width: $screen-md-min) {
	.container-fluid {
  		padding: $layout-fluid-padding;
	}
}