$footer-color: lighten($general-font-color, 15%);

.c-layout-footer.c-layout-footer-1 {
	padding: 0;
	margin: 0;

	.c-prefooter {
		padding: 60px 0;
		background: #394048; 

		.c-logo {
			padding-bottom: 20px;
		}

		.c-about {
			color: $footer-color;
			font-size: 17px;
			font-weight: 500;
			padding: 20px 0 10px 0;
		}

		.c-links {
			padding: 0 0 10px 0;
			@include clearfix();

			.c-nav {
				padding: 0;
				margin: 0 40px 0 0;
				display: inline-block;
				float: left;
				list-style: none;

				> li {
					padding: 4px 0;
					> a {
						color: lighten(#b1bcc9, 5%);
						font-size: 14px;
						font-weight: 600;
						text-transform: uppercase;

						&:hover {
							color: lighten(#b1bcc9, 20%);
						}
					}
				}
			}
		}

		.c-contact {
			color: $footer-color;
			font-size: 17px;
			font-weight: 500;
			padding: 20px 0;
		}

		.c-feedback {
			margin-left: 20px;

			h3 {
				color: #ffffff;
				font-size: 22px;
				font-weight: 600;
				text-transform: uppercase;
				padding-bottom: 10px;
			}

			form {
				padding-top: 10px;

				.form-control {
					color: lighten(#58626d, 15%);
					font-size: 17px;
					font-weight: 300;
					border-radius: 0;
					box-shadow: none;
					border-color: #474f59;
					background: transparent;
					padding: 8px 16px;
					@include placeholder(lighten(#58626d, 10%));

					&:focus {
						box-shadow: none;
						border-color: #474f59;
					}
				}

				.c-form-wrap {
					width: 100%;
					height: 100%;
					display: table;
					vertical-align: middle;
				}

				.c-form-wrap-group {
					height: 100%; 
					display: table-cell;
					vertical-align: middle;

					.form-control {
						height: 48px;				
					}

					.form-control:first-child {
						border-right-color: transparent;
					}

					.form-control:last-child {
						border-top-color: transparent;
						border-right-color: transparent;
					}

					&:last-child {
						.form-control:first-child {
							border-right-color: #474f59;
						}
						.form-control:last-child {
							border-right-color: #474f59;
						}
					}
				}

				textarea.form-control {
					border-top-color: transparent;
					height: 180px;

					&:active,
					&:focus {
						border-color: #474f59;
						border-top-color: transparent;
					}
				}

				.btn {
					&:active,
					&:hover,
					&:focus {
						background: lighten(#474f59, 5%);
						border-color: lighten(#474f59, 5%);
					}
				}
			}			
		}
	}

	.c-postfooter {
		padding: 40px 0;
		background: #2f353b;

		.c-copyright {
			color: #a4aeb8;
			padding: 0;
			margin-top: 10px;
		}

		.c-socials {
			float: right;
			list-style: none;
			padding: 0;  
			margin: 0;

			> li {
				display: inline-block;
				padding: 0 6px; 

				> a {

					> i {
						display: inline-block;
						padding: 10px;
						background: #2b3036;
						color: #64707e;
						font-size: 18px;
						font-weight: bold;
					}

					&:hover {
						> i {
							background: darken(#2b3036, 5%);
							color: lighten(#64707e, 5%);
						}
					}
				}
			}
		}
	}
}

// BEGIN: Desktop mode

@media (min-width: $screen-md-min) { /* 992px */
	.c-layout-footer.c-layout-footer-1 {
		.c-prefooter {

			> .container-fluid {
				padding: $layout-fluid-padding;
			}
		}

		.c-postfooter {

			> .container-fluid {
				padding: $layout-fluid-padding;
			}
		}
	}
}

// END: Desktop mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-footer.c-layout-footer-1 {
		.c-prefooter {

			.c-feedback {
				margin: 0;
			}
		}
	}
}